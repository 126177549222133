import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import useInfoByHost from '../hooks/useInfoByHost';
import background_header from '../images/background_header.png';
import background_footer from '../images/background_footer.png';
import comp1 from '../images/comp1.png';
import comp2 from '../images/comp2.png';
import comp3 from '../images/comp3.png';
import comp4 from '../images/comp4.png';
import fazhan from '../images/fazhan.png';
import jiaoyu from '../images/jiaoyu.png';
import keyan from '../images/keyan.png';
import shifan from '../images/shifan.png';
import beida from '../images/beida.png';

export default function PcIndex() {
  const [scrollY, setScrollY] = useState(0);
  const [scrollY2, setScrollY2] = useState(0);
  const [openAnimation, setOpenAnimation] = useState(true);
  const [keyWords, setKeyWords] = useState('manager');

  const scrollFunc = (e) => {
    if (scrollY < 1965) {
      e = e || window.event;
      if (window.scrollY >= 2000) {
        document.removeEventListener('DOMMouseScroll', scrollFunc, false);
      } else {
        setScrollY(window.scrollY);
      }
    } else {
      return;
    }
  };

  const scrollFunc2 = (e) => {
    e = e || window.event;
    setScrollY2(window.scrollY);
  };
  //给页面绑定滑轮滚动事件
  if (document.addEventListener) {
    //firefox
    // eslint-disable-next-line no-unused-expressions
    document.addEventListener('DOMMouseScroll', scrollFunc, false);
    document.addEventListener('DOMMouseScroll', scrollFunc2, false);
  }
  //滚动滑轮触发scrollFunc方法 //ie 谷歌
  window.onmousewheel = document.onmousewheel = () => {
    scrollY > 2000 ? setScrollY(2000) : scrollFunc();
    scrollFunc2();
  };

  const key = useMemo(() => {
    if (scrollY2 > 1000 && scrollY2 < 1500) {
      return 'manager';
    }
    if (scrollY2 >= 1500 && scrollY2 < 1890) {
      return 'activity';
    }
    if (scrollY2 >= 1890 && scrollY2 < 2290) {
      return 'pingke';
    }
    if (scrollY2 >= 2290) {
      return 'school';
    }
  }, [scrollY2]);

  useEffect(() => {
    setKeyWords(key);
  }, [key]);

  const handleToPan = (e) => {
    e = e || window.event;
    let current = document.getElementsByClassName('aa')[0].offsetTop - 150;
    document.body.scrollTop = document.documentElement.scrollTop = current;
    setOpenAnimation(false);
    setKeyWords('manager');
  };
  const handleToHaverst = (e) => {
    e = e || window.event;
    let current = document.getElementsByClassName('bb')[0].offsetTop - 130;
    document.body.scrollTop = document.documentElement.scrollTop = current;
    setOpenAnimation(false);
    setKeyWords('activity');
  };
  const handleToPingke = (e) => {
    e = e || window.event;
    let current = document.getElementsByClassName('cc')[0].offsetTop - 130;
    document.body.scrollTop = document.documentElement.scrollTop = current;
    setOpenAnimation(false);
    setKeyWords('pingke');
  };
  const handleToResource = (e) => {
    e = e || window.event;
    let current = document.getElementsByClassName('dd')[0].offsetTop - 130;
    document.body.scrollTop = document.documentElement.scrollTop = current;
    setOpenAnimation(false);
    setKeyWords('school');
  };

  const {
    headerLeftLogo,
    dongtu,
    yanxiuButtonName,
    cangkuButtonName,
    insightButtonName,
    handleOpenYanxiuLink,
    handleOpenCangkuLink,
    handleOpenInsightLink,
    // handleOpenDemoLink,
    ICPInfo,
  } = useInfoByHost();

  return (
    <React.Fragment>
      <Wrapper>
        <Stairs className={scrollY2 > 66 ? 'active' : ''}>
          <div className='header-wrapper'>
            <Nav style={{ height: 66 }}>
              <Logo2>
                <img alt='' className='img' src={headerLeftLogo} />
              </Logo2>
              <BtnList>
                {/* <Button className="down-load" onClick={()=>window.open('/download/index.html')}>APP下载</Button> */}
                {yanxiuButtonName && (
                  <Button className='stair-load' onClick={handleOpenYanxiuLink}>
                    {yanxiuButtonName}
                  </Button>
                )}
                {cangkuButtonName && (
                  <Button className='stair-load' onClick={handleOpenCangkuLink}>
                    {cangkuButtonName}
                  </Button>
                )}
                {insightButtonName && (
                  <Button
                    className='stair-load'
                    onClick={handleOpenInsightLink}
                  >
                    {insightButtonName}
                  </Button>
                )}
              </BtnList>
            </Nav>
          </div>
          <div
            // className=""
            className={scrollY2 > 900 ? 'active nav-wrapper' : 'nav-wrapper'}
          >
            <div>
              <div className='wrapper'>
                <StairItem
                  className={keyWords === 'manager' ? 'active' : ''}
                  onClick={handleToPan}
                >
                  数据应用
                </StairItem>
                <StairItem
                  className={keyWords === 'activity' ? 'active' : ''}
                  onClick={handleToHaverst}
                >
                  智能助手应用
                </StairItem>
                <StairItem
                  className={keyWords === 'pingke' ? 'active' : ''}
                  onClick={handleToPingke}
                >
                  工具应用
                </StairItem>
                <StairItem
                  className={keyWords === 'school' ? 'active' : ''}
                  onClick={handleToResource}
                >
                  智库引领
                </StairItem>
              </div>
            </div>
          </div>
        </Stairs>

        <Header>
          <Nav>
            <Logo>
              <img alt='' className='img' src={headerLeftLogo} />
            </Logo>
            <BtnList>
              {/* <Button className="down-load" onClick={()=>window.open('/download/index.html')}>APP下载</Button> */}
              <Button className='on-load' onClick={handleOpenYanxiuLink}>
                {yanxiuButtonName}
              </Button>
              {cangkuButtonName && (
                <Button className='on-load' onClick={handleOpenCangkuLink}>
                  {cangkuButtonName}
                </Button>
              )}
              {insightButtonName && (
                <Button className='on-load' onClick={handleOpenInsightLink}>
                  {insightButtonName}
                </Button>
              )}
            </BtnList>
          </Nav>
          <HeaderContent>
            <div className='left'>
              <div className='title'>数智赋能教师发展</div>
              <div className='tips'>专注教师 / 数智驱动 / 智库引领</div>
            </div>
            <div className='right'>
              <img alt='' src={dongtu} />
            </div>
          </HeaderContent>
        </Header>

        <Content>
          <TopMenu>
            <div className='title'>数智赋能教师发展</div>
            <div className='icon-list'>
              <div className='icon-item' onClick={handleToPan}>
                <div className='icon'>
                  <div className='icon-wrapper'></div>
                  <span className='iconfont'>&#xe67c;</span>
                  <div className='icon-info'>数据应用</div>
                </div>
              </div>
              <div className='icon-item' onClick={handleToHaverst}>
                <div className='icon'>
                  <div className='icon-wrapper'></div>
                  <span className='iconfont'>&#xe68e;</span>
                  <div className='icon-info'>智能助手应用</div>
                </div>
              </div>
              <div className='icon-item' onClick={handleToPingke}>
                <div className='icon'>
                  <div className='icon-wrapper'></div>
                  <span className='iconfont'>&#xe683;</span>
                  <div className='icon-info'>工具应用</div>
                </div>
              </div>
              <div className='icon-item' onClick={handleToResource}>
                <div className='icon'>
                  <div className='icon-wrapper'></div>
                  <span className='iconfont'>&#xe690;</span>
                  <div className='icon-info'>智库引领</div>
                </div>
              </div>
            </div>
          </TopMenu>
          <IntroduceWrapper className='aa'>
            <Introduce>
              <div className='left'>
                <div>
                  <div className='title'>数智赋能教师管理决策</div>
                  <div className='en'></div>
                  <div className='content'>
                    聚焦领导关切，实时监测师资结构和队伍发展。
                    <br />
                    <div style={{ height: 5 }}></div>
                    局校一体，数据贯通，驾驶舱和数据报告一体化。
                    <div style={{ height: 5 }}></div>
                    生成式AI智能图表助手，将管理者的管理需求直接转化为可视化图表。
                  </div>
                </div>
              </div>
              <div className='right'>
                <div className='circle'>
                  {openAnimation && scrollY !== 0 ? (
                    <img
                      alt=''
                      className={scrollY > 200 ? 'img active' : 'img'}
                      src={comp1}
                    />
                  ) : (
                    <img alt='' className='img2' src={comp1} />
                  )}
                </div>
              </div>
            </Introduce>
          </IntroduceWrapper>
          <IntroduceWrapper background={'#0033CC'} className='bb'>
            <Introduce>
              <div className='right2'>
                <div className='circle'>
                  {openAnimation && scrollY !== 0 ? (
                    <img
                      alt=''
                      className={scrollY > 1000 ? 'img active' : 'img'}
                      src={comp2}
                    />
                  ) : (
                    <img alt='' className='img2' src={comp2} />
                  )}
                </div>
              </div>
              <div className='left'>
                <div className='right'>
                  <div className='title'>
                    <b>数智赋能教师教师专业发展</b>
                  </div>
                  <div className='en'></div>
                  <div className='content right'>
                    针对教师提问，及时推送经过大模型调优的个性化回答。
                    <br />
                    <div style={{ height: 5 }}></div>
                    推送和问题相关的师训资源，自动定位到相关的视频切片。
                    <div style={{ height: 5 }}></div>
                    建立“助手+社区”模式，互促共学，并为每个教师生成智能问学报告。
                  </div>
                </div>
              </div>
            </Introduce>
          </IntroduceWrapper>
          <IntroduceWrapper className='cc'>
            <Introduce>
              <div className='left'>
                <div>
                  <div className='title'>工具应用赋能教师教研科研</div>
                  <div className='en'></div>
                  <div className='content'>
                    6大工具赋能教师日常教学和科研工作的方方面面。
                    <br />
                    <div style={{ height: 5 }}></div>
                    档案系统、教师职评、精准学习
                    <div style={{ height: 5 }}></div>
                    研修系统、听评课、调研库
                  </div>
                </div>
              </div>
              <div className='right3'>
                <div className='circle'>
                  {openAnimation && scrollY !== 0 ? (
                    <img
                      alt=''
                      className={scrollY > 1230 ? 'img active' : 'img'}
                      src={comp3}
                    />
                  ) : (
                    <img alt='' className='img2' src={comp3} />
                  )}
                </div>
              </div>
            </Introduce>
          </IntroduceWrapper>
          <IntroduceWrapper background={'#0033CC'} className='dd'>
            <Introduce>
              <div className='right4'>
                {openAnimation && scrollY !== 0 ? (
                  <img
                    alt=''
                    className={scrollY > 1890 ? 'img active' : 'img'}
                    src={comp4}
                  />
                ) : (
                  <img alt='' className='img2' src={comp4} />
                )}
              </div>
              <div className='left'>
                <div className='right'>
                  <div className='title'>智库引领</div>
                  <div className='en'></div>
                  <div className='content right'>
                    持续跟踪教育数改实践，教师发展科研成果转化。
                    <br />
                    <div style={{ height: 5 }}></div>
                    国家级课题承接单位、北京地区研究智库、港中文联合教科研研究
                  </div>
                </div>
              </div>
            </Introduce>
          </IntroduceWrapper>
          <ImgList>
            <div className='title'>合作单位</div>
            <div className='imgs'>
              <div
                className='img-wrapper'
                onClick={() => window.open(' http://www.bjesr.cn/')}
              >
                <img alt='' className='img' src={keyan} />
              </div>
              <div
                className='img-wrapper'
                onClick={() => window.open(' https://www.bnu.edu.cn/')}
              >
                <img alt='' className='img' src={shifan} />
              </div>
              <div
                className='img-wrapper'
                onClick={() => window.open('https://www.bjie.ac.cn/')}
              >
                <img alt='' className='img' src={jiaoyu} />
              </div>
              <div
                className='img-wrapper'
                onClick={() =>
                  window.open(' http://www.cseds.edu.cn/edoas2/zlxh/index.jsp')
                }
              >
                <img alt='' className='img' src={fazhan} />
              </div>
              <div
                className='img-wrapper'
                onClick={() => window.open('http://www.gse.pku.edu.cn/')}
              >
                <img alt='' className='img' src={beida} />
              </div>
            </div>
          </ImgList>
        </Content>

        <Footer>
          <div className='top'>
            <div className='item'>
              <div className='count'>8+</div>
              <div className='name'>覆盖省份</div>
            </div>
            <div className='item'>
              <div className='count'>20+</div>
              <div className='name'>服务区域</div>
            </div>
            <div className='item'>
              <div className='count'>3500+</div>
              <div className='name'>覆盖学校</div>
            </div>
            <div className='item'>
              <div className='count'>490,000+</div>
              <div className='name'>服务教师</div>
            </div>
          </div>
          <div className='bottom'>
            <div>marketing@xiaozhistar.com</div>
            <div>
              <a href='https://beian.miit.gov.cn/' target='_blank'>
                {ICPInfo}
              </a>{' '}
              北京小致教育科技有限公司
            </div>
            <div>Copyright © 2020 All rights reserved</div>
          </div>
        </Footer>
      </Wrapper>
    </React.Fragment>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const Stairs = styled.div`
  width: 100%;
  height: max-content;
  background: white;
  position: sticky;
  top: 0;
  z-index: 3;
  display: block;
  opacity: 0;
  > .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 100%;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0);
  }
  > .nav-wrapper {
    display: none;
    opacity: 0;
    &.active {
      animation: mynavshow2 1s;
      display: block;
      opacity: 1;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 65px;
      width: 100%;
      box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(255, 255, 255, 0);
      > .wrapper {
        width: 100%;
        max-width: 1200px;
        height: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &.active {
    animation: mynavshow 1s;
    display: block;
    opacity: 1;
  }
  @keyframes mynavshow {
    from {
      display: none;
      opacity: 0;
    }
    to {
      display: block;
      opacity: 1;
    }
  }

  @keyframes mynavshow2 {
    from {
      display: none;
      opacity: 0;
    }
    to {
      display: block;
      opacity: 1;
    }
  }
`;

const StairItem = styled.div`
  padding: 0 40px;
  height: 63px;
  line-height: 63px;
  font-size: 16px;
  border-bottom: 2px solid #fff;
  cursor: pointer;
  &.active {
    border-bottom: 2px solid #0033cc;
    color: #0033cc;
    font-weight: bold;
  }
`;

const Header = styled.div`
  margin-top: -66px;
  height: 810px;
  background: url(${background_header}) no-repeat center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Nav = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
`;

const Logo = styled.div`
  width: 146px;
  height: 35px;
  > .img {
    height: 100%;
    width: auto;
  }
`;

const Logo2 = styled.div`
  width: 154px;
  height: 50px;
  > .img {
    height: 100%;
    width: auto;
  }
`;

const BtnList = styled.div`
  display: flex;
`;

const Button = styled.div`
  width: 150px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  border-radius: 58px;
  cursor: pointer;
  &.down-load {
    background: #15db95;
  }
  &.on-load {
    border: 1px solid #fff;
    margin-left: 15px;
  }
  &.stair-load {
    border: 1px solid #15db95;
    margin-left: 15px;
    color: #15db95;
  }
  &.demo {
    background: #fff;
    color: #2551d3;
    font-weight: bold;
    font-size: 14px;
    margin-top: 50px;
  }
  &.demo2 {
    background: #15db95;
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-top: 50px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  width: 100%;
  > .left {
    margin-top: 197px;
    color: #fff;
    > .title {
      height: 40px;
      font-size: 28px;
      font-weight: bold;
      line-height: 40px;
    }
    > .tips {
      margin-top: 10px;
      height: 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  > .right {
    height: 414px;
    width: 561px;
    margin-top: 83px;
    animation: mymove 4s;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    > img {
      height: 416px;
      width: 567px;
    }
  }

  @keyframes mymove {
    from {
      margin-top: -100px;
      opacity: 0;
    }
    to {
      margin-top: 85px;
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  scroll-behavior: smooth;
`;

const IntroduceWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.background ? props.background : 'white')};
`;

const TopMenu = styled.div`
  height: 256px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin-top: 80px;
  > .title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
  }
  > .icon-list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    > .icon-item {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > .icon {
        height: 75px;
        width: 75px;
        border-radius: 50%;
        border: 1px solid #d7def6;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        > .icon-wrapper {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          border: 1px solid #0033cc;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        > .iconfont {
          position: absolute;
          top: 20px;
          left: 21px;
          font-family: 'iconfont' !important;
          font-size: 32px;
          font-style: normal;
          color: #0033cc;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        > .icon-info {
          position: absolute;
          margin-top: 10px;
          height: 25px;
          font-size: 16px;
          line-height: 25px;
          top: 84px;
          width: 130px;
          text-align: center;
        }
        &:hover {
          animation: colorchange1 0.5s;
          > .icon-wrapper {
            animation: colorchange2 0.5s;
            width: 60px;
            height: 60px;
            background: #0033cc;
          }
          > .iconfont {
            color: #fff;
          }
          > .icon-info {
            animation: colorchange3 0.5s;
          }
        }
      }
    }
  }
  @keyframes colorchange1 {
    from {
      border: 1px solid #d7def6;
    }
    to {
      border: 1px solid #0033cc;
    }
  }
  @keyframes colorchange2 {
    from {
      width: 0;
      height: 0;
      background: #fff;
    }
    to {
      width: 60px;
      height: 60px;
      background: #0033cc;
    }
  }
  @keyframes colorchange3 {
    from {
      color: #000;
    }
    to {
      color: #0033cc;
    }
  }
`;

const Footer = styled.div`
  margin-top: -200px;
  height: 722px;
  width: 100%;
  background: url(${background_footer}) no-repeat bottom;
  background-size: 100% 62%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  > .top {
    display: flex;
    max-width: 1100px;
    width: 100%;
    justify-content: center;
    margin-bottom: 55px;
    > .item:last-child {
      border-right: 0;
    }
    > .item {
      border-right: 1px solid #fff;
      flex: 1;
      max-width: 174px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      > .count {
        height: 42px;
        line-height: 42px;
        font-size: 28px;
        font-weight: bold;
      }
      > .name {
        height: 21px;
        line-height: 21px;
        font-size: 14px;
      }
    }
  }
  > .bottom {
    display: flex;
    opacity: 0.7;
    width: 100%;
    background-color: rgba(21, 219, 149, 0.7);
    justify-content: center;
    align-items: center;
    > div {
      flex: 1;
      color: #000;
      text-align: center;
      height: 100px;
      line-height: 100px;
      max-width: 426px;
    }
  }
`;

const Introduce = styled.div`
  height: 500px;
  display: flex;
  width: 100%;
  max-width: 1280px;
  > .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div {
      margin-left: 100px;
      &.right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 100px;
        color: white;
      }
      > .title {
        font-size: 28px;
        height: 40px;
        line-height: 40px;
        /* font-weight: bold; */
      }
      > .en {
        margin-top: 10px;
        margin-bottom: 45px;
      }
      > .content {
        margin-bottom: 30px;
        max-width: 518px;
        font-size: 14px;
        &.right {
          text-align: right;
        }
      }
    }
  }
  > .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    > .circle {
      position: relative;
      height: 443px;
      width: 443px;
      border-radius: 50%;
      background: #0033cc;
      > .img {
        position: absolute;
        // width: 480px;
        height: 256px;
        left: -220px;
        top: 87px;
        opacity: 0;
        &.active {
          animation: mymove1 3s;
          opacity: 1;
        }
      }
      > .img2 {
        position: absolute;
        // width: 480px;
        height: 256px;
        left: -220px;
        top: 87px;
        opacity: 1;
      }
    }
  }

  > .right2 {
    flex: 1;
    display: flex;
    align-items: center;
    > .circle {
      position: relative;
      height: 443px;
      width: 443px;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .img {
        position: absolute;
        height: 402px;
        // width: 445px;
        left: 35px;
        /* top: 87px; */
        opacity: 0;
        &.active {
          animation: mymove2 2s;
          opacity: 1;
        }
      }
      > .img2 {
        position: absolute;
        height: 402px;
        // width: 445px;
        left: 35px;
        /* top: 87px; */
        opacity: 1;
      }
    }
  }
  > .right3 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    > .circle {
      position: relative;
      height: 443px;
      width: 443px;
      border-radius: 50%;
      > .img {
        position: absolute;
        // width: 336px;
        height: 256px;
        right: -15px;
        top: 87px;
        opacity: 0;
        &.active {
          animation: mymove3 2s;
          opacity: 1;
        }
      }
      > .img2 {
        position: absolute;
        // width: 336px;
        height: 256px;
        right: -15px;
        top: 87px;
        opacity: 1;
      }
    }
  }

  > .right4 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    > .img {
      // width: 440px;
      height: 360px;
      opacity: 0;
      &.active {
        animation: mymove4 2s;
        opacity: 1;
      }
    }
    > .img2 {
      // width: 440px;
      height: 360px;
      opacity: 1;
    }
  }
  @keyframes mymove1 {
    from {
      left: 180px;
      opacity: 0;
    }
    to {
      left: -220px;
      opacity: 1;
    }
  }
  @keyframes mymove2 {
    from {
      left: -100px;
      opacity: 0;
    }
    to {
      left: 35px;
      opacity: 1;
    }
  }
  @keyframes mymove3 {
    from {
      right: -140px;
      opacity: 0;
    }
    to {
      right: -15px;
      opacity: 1;
    }
  }
  @keyframes mymove4 {
    from {
      margin-left: -200px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
`;

const ImgList = styled.div`
  margin-top: 90px;
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .title {
    font-size: 28px;
    height: 42px;
    line-height: 42px;
    font-weight: bold;
  }
  > .imgs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    > .img-wrapper {
      padding: 5px;
      margin-right: 45px;
      cursor: pointer;
      user-select: none;
      > .img {
        height: 30px;
      }
    }
  }
`;
